import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function CustomMarkerForm({ onClose, onSubmit, corArr }) {
  const [formData, setFormData] = useState({
    description: '',
    link: '',
    img: '',
    type: '',
    coordinates: corArr,
  });

  const handleChange = (e) => {
    if (e.target.name === 'img' && e.target.files.length > 0) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0], // Store the File object directly
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        formDataToSend.append(key, formData[key]);
      }
    }
    onSubmit(formDataToSend);
    onClose();
  };

  const handleClose = (e) => {
    onClose();
  };

  return (
    <>
      <form className="px-5 py-3" onSubmit={handleSubmit}>
        <div className='mb-3'>
          <label className='form-label'>Type</label>
          <input
            type="text"
            className='form-control'
            name="type"
            onChange={handleChange}
            required
            maxLength={20}
          />
        </div>
        <div className='mb-3'>
          <input
            type="hidden"
            name="coordinates"
            value={formData.coordinates || ''}
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label htmlFor="title">Title</label>
          <input
            className='form-control'
            name="title"
            onChange={handleChange}
            required
            maxLength={20}
          />
        </div>
        <div>
          <label className='form-label' htmlFor="link">Link</label>
          <input
            name="link"
            className='form-control'
            onChange={handleChange}
            pattern="^(ftp|http|https)?:\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL (e.g., http://example.com)"
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            as="textarea"
            rows={3}
            className='form-control'
            onChange={handleChange}
            required
            maxLength={150}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label' htmlFor="img">Image Upload</label>
          <input
            type='file'
            name="img"
            className='form-control'
            onChange={handleChange}
          />
        </div>
        <div className='d-flex gap-3 float-end'>
          <button type="button" onClick={handleClose} className='btn btn-secondary'>Cancel</button>
          <button type="submit" className='btn btn-primary'>Submit</button>
        </div>
      </form>
    </>
  );
}

export default CustomMarkerForm;
