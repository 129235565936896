import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import CustomMarkerForm from './CustomMarkerForm';
import { Dialog, Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import config from './config';

function MapboxComponent() {
  const [isFormOpen, setFormOpen] = useState(false);
  const [maplatlog, setLatlon] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    initializeMap();
  }, []);

  const initializeMap = () => {
    mapboxgl.accessToken = config.mapboxToken
    mapRef.current = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 1,
      center: [30, 50],
    });

    const directions = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: 'metric',
        alternatives: true,
        controls: {
          instructions: true,
          profileSwitcher: true,
        },
      });

      mapRef.current.addControl(directions, 'top-left');
      let lastClickTime = 0;
      const doubleClickDelay = 300;
  
      mapRef.current.on('click', (e) => {
        const { lng, lat } = e.lngLat;
        const currentTime = new Date().getTime();
        const timeSinceLastClick = currentTime - lastClickTime;
  
        if (timeSinceLastClick < doubleClickDelay) {
          setFormOpen(true);
        } else {
          const coordinates = [lng, lat];
          setLatlon(coordinates);
          setFormOpen(false);
          if (!directions.getDestination()) {
            directions.setOrigin(coordinates);
          } else {
            directions.setDestination(coordinates);
          }
        }
        lastClickTime = currentTime;
      });
      fetchMarkers();
      return () => {
        mapRef.current.remove();
      };
  };
  const fetchMarkers = () => {
    axios.get(`${config.apiBaseUrl}/getMarkes`)
      .then((response) => {
        const fetchedMarkers = response.data;
        setMarkers(fetchedMarkers);
        fetchedMarkers.forEach((marker) => {
          const popup = createMarkerPopup(marker);
          if (marker.coordinates && marker.coordinates.length === 1) {
            const [coordinatesString] = marker.coordinates;
            const [lng, lat] = coordinatesString.split(',').map(Number);
            new mapboxgl.Marker()
              .setLngLat([lng, lat])
              .setPopup(popup)
              .addTo(mapRef.current);
          }
        });
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };

  const createMarkerPopup = (marker) => {
    const markerId = marker._id;
    const popupContent = document.createElement('div');
    
    popupContent.innerHTML = `
      <div class="div-left">
        <a class="deleteMarkerButton" data-markerid="${markerId}"> Delete </a>
      </div>
      <div>
        <h3> <a class="title" href="${marker.link}" target="_blank">${marker.title}</a></h3>
        <a href="${marker.link}" target="_blank">${marker.link}</a>
        <p>${marker.description}</p>
      </div>
    `;
    
    if (marker.img) {
      const imgContainer = document.createElement('div');
      imgContainer.innerHTML = `<img src="https://mapbox-api.devtrust.biz/uploads/${marker.img}" alt="${marker.img}" style="max-width: 100%; border-radius: 50%;">`;
      popupContent.appendChild(imgContainer);
    }
    
    return new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContent);
  };
  
  document.addEventListener('click', (event) => {
    if (event.target.classList.contains('deleteMarkerButton')) {
      const markerId = event.target.getAttribute('data-markerid');
      deleteMarker(markerId);
    }
  });

  const deleteMarker = (markerId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this marker',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${config.apiBaseUrl}/deleteMarkerById/${markerId}`)
          .then((response) => {
            toast.success(response.data.message);
            fetchMarkers()
          })
          .catch((error) => {
            toast.error('Error: Something went wrong.');
          });
      }
    });
  };

  const handleFormSubmit = (formData) => {
    axios.post(`${config.apiBaseUrl}/saveCustomMarker`, formData)
      .then((response) => {
        toast.success(response.data.message);
        fetchMarkers()
        setFormOpen(false);
      })
      .catch((error) => {
        toast.error('Error: Something went wrong.');
      });
  };
  const handleMarkerSelect = (event, value) => {
    setSelectedMarker(value);
    value = value._id;
    if (value) {
      const selectTitle = markers.find((marker) => marker._id === value);
      if (selectTitle) {
        const [lng, lat] = selectTitle.coordinates[0].split(',').map(Number);
        mapRef.current.flyTo({ center: [lng, lat], essential: true, zoom: 6 });
      }
    }
  };
  const handleClearSelection = () => {
    setSelectedMarker(null);
   initializeMap();
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        options={markers.map((marker) => ({ _id: marker._id, title: marker.title }))}
        value={selectedMarker}
        onChange={(event, newValue) => {
          if (!newValue) {
            handleClearSelection();
          } else {
            handleMarkerSelect(event, newValue);
          }
        }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => <TextField {...params} label="Select a Marker" />}
        className="custom-autocomplete"
      />
      <div id="map"></div>

      <Dialog
        open={isFormOpen}
        onClose={handleCloseForm}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <CustomMarkerForm onClose={handleCloseForm} onSubmit={handleFormSubmit} corArr={maplatlog} />
      </Dialog>
    </div>
  );
}

export default MapboxComponent;
